var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../../assets/image/productCase/banner-catering-1.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"60rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explainBox",staticStyle:{"height":"auto","min-height":"15.3rem"}},[_c('div',{staticClass:"head-explainBox-title1"},[_vm._v("IT 服务门户")]),_c('div',{staticClass:"head-explainBox-content1"},[_vm._v("某全国连锁餐饮企业")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"selected-box"},[_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("高峰期问题频发")]),_c('div',{staticClass:"content"},[_vm._v("该连锁餐饮品牌在全国拥有50多家门店，面临着门店分散导致管理难度加大，高峰期导致系统过压不稳定。")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("人工操作问题多")]),_c('div',{staticClass:"content"},[_vm._v("店员IT基础薄弱，操作问题频发，且由于权限管理不灵活，导致问题解决延误，损失扩大。")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("系统更新维护困难")]),_c('div',{staticClass:"content"},[_vm._v("由于门店的分散，各门店系统更新与维护困难，难以统一进行。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-catering-1.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("统一界面")]),_c('div',{staticClass:"title2"},[_vm._v("对各门店IT系统的集中监控和管理，IT管理员能够实时查看各门店不同时间段的系统状态。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-catering-2.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("自动化")]),_c('div',{staticClass:"title2"},[_vm._v("通过自动化，备份、系统更新等日常任务得到高效处理。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-catering-3.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("智能客服")]),_c('div',{staticClass:"title2"},[_vm._v("AI智能客服可以提供日常问答、企业内部知识问答及人工服务。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("IT可以及时发现并解决潜在问题，使得系统维护的响应速度提高了 40%，从而大大降低了系统故障对门店运营的影响，确保了业务的连续性和稳定性。")])]),_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("店员遇到问题时，直接通过AI智能客服迅速获取解决方案，减少对IT部门的依赖。")])]),_c('div',{staticClass:"purchase-title purchase-content-item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"purchase-title-label"},[_vm._v("服务过程中的信息和操作，完整记录下来，且数据安全且可靠。")])])])
}]

export { render, staticRenderFns }